<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form"  :rules="rules">
      <a-form-model-item prop="sampleName" >
        <span slot="label" >
          <a-tooltip>
            {{$t('模板名称,设备类型')}}<template slot="title">{{$t('模板名称,设备类型')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.sampleName" :maxLength="20" :placeholder="$t('通用.输入.请输入')+$t('模板名称,设备类型')" />
      </a-form-model-item>
      <a-form-model-item prop="sampleType" >
        <span slot="label" >
          <a-tooltip>
            {{$t('模板类型(0.网关子设备模板 1.直连设备 2.网关模板)')}}<template slot="title">{{$t('模板类型(0.网关子设备模板 1.直连设备 2.网关模板)')}}</template>
          </a-tooltip>
        </span>
      </a-form-model-item>
      <a-form-model-item prop="chargeType" >
        <span slot="label" >
          <a-tooltip>
            {{$t('计费方式 0-云端计费 1-设备计费')}}<template slot="title">{{$t('计费方式 0-云端计费 1-设备计费')}}</template>
          </a-tooltip>
        </span>
      </a-form-model-item>
      <a-form-model-item prop="deviceTypeIcon" >
        <span slot="label" >
          <a-tooltip>
            {{$t('设备类型标签')}}<template slot="title">{{$t('设备类型标签')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.deviceTypeIcon" :placeholder="$t('通用.输入.请输入')" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item prop="deviceTypeIconPressed" >
        <span slot="label" >
          <a-tooltip>
            {{$t('设备类型压下标签')}}<template slot="title">{{$t('设备类型压下标签')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.deviceTypeIconPressed" :placeholder="$t('通用.输入.请输入')" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item prop="devTypePopupIcon" >
        <span slot="label" >
          <a-tooltip>
            {{$t('设备弹窗图标')}}<template slot="title">{{$t('设备弹窗图标')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.devTypePopupIcon" :placeholder="$t('通用.输入.请输入')" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item prop="connType" >
        <span slot="label" >
          <a-tooltip>
            {{$t('接入类型(0.wifi 1.以太网 2.2G 3.3G 4.4G 5.5G 6.NB-iot 7.其他)')}}<template slot="title">{{$t('接入类型(0.wifi 1.以太网 2.2G 3.3G 4.4G 5.5G 6.NB-iot 7.其他)')}}</template>
          </a-tooltip>
        </span>
      </a-form-model-item>
      <a-form-model-item prop="netType" >
        <span slot="label" >
          <a-tooltip>
            {{$t('配网方式 0-无需配网 1-蓝牙 2-蓝牙+wifi 3-其他 4-wifi')}}<template slot="title">{{$t('配网方式 0-无需配网 1-蓝牙 2-蓝牙+wifi 3-其他 4-wifi')}}</template>
          </a-tooltip>
        </span>
      </a-form-model-item>
      <a-form-model-item prop="connProtocol" >
        <span slot="label" >
          <a-tooltip>
            {{$t('通讯协议(0.modbus-rtu 1.json 2.其他)')}}<template slot="title">{{$t('通讯协议(0.modbus-rtu 1.json 2.其他)')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.connProtocol"  :placeholder="$t('通用.输入.请输入')+$t('通讯协议(0.modbus-rtu 1.json 2.其他)')" />
      </a-form-model-item>
      <a-form-model-item prop="deviceCount" >
        <span slot="label" >
          <a-tooltip>
            {{$t('设备个数(网关模板下显示)')}}<template slot="title">{{$t('设备个数(网关模板下显示)')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.deviceCount"  :placeholder="$t('通用.输入.请输入')+$t('设备个数(网关模板下显示)')" />
      </a-form-model-item>
      <a-form-model-item prop="portId" >
        <span slot="label" >
          <a-tooltip>
            {{$t('绑定端口id(网关模板可以绑定,默认绑定com0)')}}<template slot="title">{{$t('绑定端口id(网关模板可以绑定,默认绑定com0)')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.portId"  :placeholder="$t('通用.输入.请输入')+$t('绑定端口id(网关模板可以绑定,默认绑定com0)')" />
      </a-form-model-item>
      <a-form-model-item prop="slaveCount" >
        <span slot="label" >
          <a-tooltip>
            {{$t('从机个数(网关模板下显示)')}}<template slot="title">{{$t('从机个数(网关模板下显示)')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.slaveCount"  :placeholder="$t('通用.输入.请输入')+$t('从机个数(网关模板下显示)')" />
      </a-form-model-item>
      <a-form-model-item prop="dataPointCount" >
        <span slot="label" >
          <a-tooltip>
            {{$t('变量个数(网关子设备模板下显示)')}}<template slot="title">{{$t('变量个数(网关子设备模板下显示)')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.dataPointCount"  :placeholder="$t('通用.输入.请输入')+$t('变量个数(网关子设备模板下显示)')" />
      </a-form-model-item>
      <a-form-model-item prop="collectMode" >
        <span slot="label" >
          <a-tooltip>
            {{$t('采集方式(0.云端轮询 1.边缘计算)')}}<template slot="title">{{$t('采集方式(0.云端轮询 1.边缘计算)')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.collectMode"  :placeholder="$t('通用.输入.请输入')+$t('采集方式(0.云端轮询 1.边缘计算)')" />
      </a-form-model-item>
      <a-form-model-item prop="isCostomStream" >
        <span slot="label" >
          <a-tooltip>
            {{$t('是否自定义数据流(0否 1是)')}}<template slot="title">{{$t('是否自定义数据流(0否 1是)')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.isCostomStream"  :placeholder="$t('通用.输入.请输入')+$t('是否自定义数据流(0否 1是)')" />
      </a-form-model-item>
      <a-form-model-item prop="costomStreamProtocol" >
        <span slot="label" >
          <a-tooltip>
            {{$t('自定义数据流协议(Binary Plaintext JSON Modbus-RTU DL/T645-2007 HJ212-2017)')}}<template slot="title">{{$t('自定义数据流协议(Binary Plaintext JSON Modbus-RTU DL/T645-2007 HJ212-2017)')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.costomStreamProtocol" :maxLength="20" :placeholder="$t('通用.输入.请输入')+$t('自定义数据流协议(Binary Plaintext JSON Modbus-RTU DL/T645-2007 HJ212-2017)')" />
      </a-form-model-item>
      <a-form-model-item prop="tags" >
        <span slot="label" >
          <a-tooltip>
            {{$t('标签')}}<template slot="title">{{$t('标签')}}</template>
          </a-tooltip>
        </span>
      </a-form-model-item>
      <a-form-model-item prop="icon" >
        <span slot="label" >
          <a-tooltip>
            {{$t('模板图标')}}<template slot="title">{{$t('模板图标')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.icon" :placeholder="$t('通用.输入.请输入')" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item prop="isDelete" >
        <span slot="label" >
          <a-tooltip>
            {{$t('删除标识(0未删除 1已删除)')}}<template slot="title">{{$t('删除标识(0未删除 1已删除)')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.isDelete"  :placeholder="$t('通用.输入.请输入')+$t('删除标识(0未删除 1已删除)')" />
      </a-form-model-item>
      <a-form-model-item prop="remark" >
        <span slot="label" >
          <a-tooltip>
            {{$t('备注描述')}}<template slot="title">{{$t('备注描述')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.remark" :placeholder="$t('通用.输入.请输入')" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item prop="projectId" >
        <span slot="label" >
          <a-tooltip>
            {{$t('项目id')}}<template slot="title">{{$t('项目id')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.projectId"  :placeholder="$t('通用.输入.请输入')+$t('项目id')" />
      </a-form-model-item>
      <a-form-model-item prop="deposit" >
        <span slot="label" >
          <a-tooltip>
            {{$t('押金金额')}}<template slot="title">{{$t('押金金额')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.deposit"  :placeholder="$t('通用.输入.请输入')+$t('押金金额')" />
      </a-form-model-item>
      <a-form-model-item prop="payType" >
        <span slot="label" >
          <a-tooltip>
            {{$t('支付类型 0先付后用 1先用后付')}}<template slot="title">{{$t('支付类型 0先付后用 1先用后付')}}</template>
          </a-tooltip>
        </span>
      </a-form-model-item>
      <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
        <img alt="example" style="width: 100%" :src="previewUrl" />
      </a-modal>
      <a-modal :visible="previewVideoVisible" :footer="null" @cancel="previewVideoCancel">
        <video style="width: 100%" :src="previewUrl" ref="myVideo"
               :poster="previewUrl + '?x-oss-process=video/snapshot,t_0,f_jpg'" :controls="true"></video>
      </a-modal>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{$t('通用.按钮.保存')}}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{$t('通用.按钮.取消')}}
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getSampleInfo, addSampleInfo, updateSampleInfo } from '@/api/device/sample/deviceType/sampleInfo'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag/index.vue";
import {generateFilePath, uploadObject} from "@/api/tool/oss";


export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    CustomDictTag,
  },
  data () {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      formTitle: '',
      previewImageVisible: false,
      previewUrl: '',
      previewVideoVisible: false,
      loading: '',
      // 表单参数
      form: {
        id: null,

        sampleName: null,

        sampleType: null,

        chargeType: null,

        deviceTypeIcon: null,

        deviceTypeIconPressed: null,

        devTypePopupIcon: null,

        connType: null,

        netType: null,

        connProtocol: null,

        deviceCount: null,

        portId: null,

        slaveCount: null,

        dataPointCount: null,

        collectMode: null,

        isCostomStream: null,

        costomStreamProtocol: null,

        tags: null,

        icon: null,

        createTime: null,

        isDelete: null,

        remark: null,

        projectId: null,

        deposit: null,

        payType: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        sampleType: [
          { required: true, message: this.$t('模板类型(0.网关子设备模板 1.直连设备 2.网关模板)')+this.$t('通用.文本.不能为空'), trigger: 'change' }
        ],
        chargeType: [
          { required: true, message: this.$t('计费方式 0-云端计费 1-设备计费')+this.$t('通用.文本.不能为空'), trigger: 'change' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    previewVideoCancel() {
      this.previewVideoVisible = false;
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        sampleName: null,
        sampleType: null,
        chargeType: null,
        deviceTypeIcon: null,
        deviceTypeIconPressed: null,
        devTypePopupIcon: null,
        connType: null,
        netType: null,
        connProtocol: null,
        deviceCount: null,
        portId: null,
        slaveCount: null,
        dataPointCount: null,
        collectMode: null,
        isCostomStream: null,
        costomStreamProtocol: null,
        tags: null,
        icon: null,
        createTime: null,
        isDelete: null,
        remark: null,
        projectId: null,
        deposit: null,
        payType: null
      }

    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = this.$t('通用.文本.添加')
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getSampleInfo({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = this.$t('通用.文本.修改')
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          let message = '';
          if (this.form.id !== undefined && this.form.id !== null) {
            message = this.$t('通用.文本.修改成功')
            updateSampleInfo(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            message = this.$t('通用.文本.修改成功')
            addSampleInfo(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
