import request from '@/utils/request'


// 查询模板变量设备类型列表
export function listSampleInfo(query) {
  return request({
    url: '/gather/sample/info/list',
    method: 'get',
    params: query
  })
}

// 查询模板变量设备类型分页
export function pageSampleInfo(query) {
  return request({
    url: '/gather/sample/info/page',
    method: 'get',
    params: query
  })
}

// 查询模板变量设备类型详细
export function getSampleInfo(data) {
  return request({
    url: '/gather/sample/info/detail',
    method: 'get',
    params: data
  })
}

// 新增模板变量设备类型
export function addSampleInfo(data) {
  return request({
    url: '/gather/sample/info/add',
    method: 'post',
    data: data
  })
}

// 修改模板变量设备类型
export function updateSampleInfo(data) {
  return request({
    url: '/gather/sample/info/edit',
    method: 'post',
    data: data
  })
}

// 删除模板变量设备类型
export function delSampleInfo(data) {
  return request({
    url: '/gather/sample/info/delete',
    method: 'post',
    data: data
  })
}
